import Breadcrumb from 'react-bootstrap/Breadcrumb';
import styled from 'styled-components';

export const StyledWrapperCreatableSelect = styled.div`
  &&& {
    .react-select__control--is-focused .react-select__placeholder {
      margin-left: 7px;
    }
  }
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
ol{
    background-color: white;
}

li:not(:first-child):before {
    content: ">" !important;
}
`;
