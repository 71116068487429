import React from 'react';
import {
  Container,
  BreadcrumbItem,
} from 'react-bootstrap';
import { StyledBreadcrumb } from './styled.components';


const PartnerPage = () => (
  <>
    <Container>
      <StyledBreadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem active>Manage Partners</BreadcrumbItem>
      </StyledBreadcrumb>
      <h4>Manage Partners</h4>
      <span>Coming soon...</span>
    </Container>
  </>
);

export default PartnerPage;
