import Breadcrumb from 'react-bootstrap/Breadcrumb';
import styled from 'styled-components';

export const StyledBreadcrumb = styled(Breadcrumb)`
ol{
    background-color: white;
}

li:not(:first-child):before {
    content: ">" !important;
}
`;

export const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
`;
