import { useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
} from 'react-bootstrap';
import moment, { Moment } from 'moment';
import AuthProps from '../auth/auth-props';
import IError from '../../models/Error';
import api from '../../shared/api/adminUI.api';
import { StyledBreadcrumb } from '../../shared/styled-components';
import {
  RowTitle,
  PrivacySettings,
  Contacts,
  TopRow,
  PrivacySettingsHistory,
  LockIconContainer,
} from './styled-components';
import { IIdentityInfo } from '../admissions/admissions.model';
import { toLowerCaseProps } from '../../utils/json';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import lockRedIcon from '../../assets/images/lock-red.svg';
import lockGreenIcon from '../../assets/images/lock-green.svg';


interface ILearnerPrivacySettings {
  EffectiveDate: string | null,
  Restricted: boolean,
  ReleaseAcademicInfo: boolean,
  ReleaseAccountInfo: boolean,
  PartnerName: string | null,
  AuthorizedContacts: Array<IAuthorizedContact> | null,
}

interface IAuthorizedContact {
  ContactName: string,
  ContactInformation: string,
}

interface ILearnerPrivacySettingsHistoryItem {
  effectiveDate: string | null,
  restricted: boolean,
  releaseAcademicInfo: boolean,
  releaseAccountInfo: boolean,
  partnerName: string | null,
  authorizedContacts: Array<IHistoricalAuthorizedContact>,

  effectiveFrom: Moment | null,
  effectiveTill: Moment | null,
}

interface IHistoricalAuthorizedContact {
  contactName: string,
  contactInformation: string,
}

const LearnerPrivacySettingsPage = (props: AuthProps) => {
  const { getBearerToken } = props;

  const { learnerId } = useParams() as { learnerId : string };
  const [privacySettings, setPrivacySettings] = useState<ILearnerPrivacySettings>({
    Restricted: false,
    ReleaseAcademicInfo: false,
    ReleaseAccountInfo: false,
    EffectiveDate: null,
    PartnerName: null,
    AuthorizedContacts: null,
  });
  const [privacySettingsResponseError, setPrivacySettingsResponseError] = useState<IError | null>(null);
  const [privacySettingsStatus, setPrivacySettingsStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');

  const [history, setHistory] = useState<ILearnerPrivacySettingsHistoryItem[]>([]);
  const [historyResponseError, setHistoryResponseError] = useState<IError | null>(null);
  const [historyStatus, setHistoryStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');

  const [officialName, setOfficialName] = useState<string>('');
  const [identityStatus, setIdentityStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');
  const [identityResponseError, setIdentityResponseError] = useState<IError | null>(null);

  const location = useLocation();

  const fetchOfficialName = async () => {
    try {
      setIdentityStatus('loading');
      const response = await api.learnerIdentity.getIdentityInfo(learnerId, getBearerToken);
      if (!response.ok) throw response;

      const identityInfo: IIdentityInfo = toLowerCaseProps(
        await response.json(),
      );

      setOfficialName(identityInfo?.officialName);
      setIdentityStatus('success');
    } catch (err) {
      setIdentityStatus('error');
      setIdentityResponseError(err as IError);
    }
  };

  const fetchPrivacySettings = async () => {
    try {
      setPrivacySettingsStatus('loading');
      const response = await api.privacySettings.current(learnerId, getBearerToken);
      if (!response.ok) throw response;

      if (response.status === 200) { // 204 means the learner hasn't set anything
        const settings = (await response.json()) as ILearnerPrivacySettings;
        setPrivacySettings(settings);
      }

      setPrivacySettingsStatus('success');
    } catch (err) {
      setPrivacySettingsStatus('error');
      setPrivacySettingsResponseError(err as IError);
    }
  };

  const fetchHistory = async () => {
    try {
      setHistoryStatus('loading');
      const response = await api.history.getLearnerPrivacySettingsHistory(learnerId, getBearerToken);
      if (!response.ok) throw response;

      const historyResponse = (await response.json()) as ILearnerPrivacySettingsHistoryItem[];

      if (historyResponse.length > 1) {
        // enrich data with effective date of the previous entry
        for (let i = 1; i < historyResponse.length; i += 1) {
          historyResponse[i].effectiveFrom = moment(historyResponse[i].effectiveDate).startOf('day');
          historyResponse[i].effectiveTill = moment(historyResponse[i - 1].effectiveDate).startOf('day').add(-1, 'd');

          if ((historyResponse[i].effectiveTill || moment()) <= (historyResponse[i].effectiveFrom || moment())) {
            historyResponse[i].effectiveTill = null;
          }
        }
      }

      setHistory(historyResponse);
      setHistoryStatus('success');
    } catch (err) {
      setHistoryStatus('error');
      setHistoryResponseError(err as IError);
    }
  };

  useEffect(() => {
    if (location.state && location.state.officialName) {
      setOfficialName(location.state.officialName);
      setIdentityStatus('success');
    } else {
      fetchOfficialName();
    }
  }, []);

  useEffect(() => { fetchPrivacySettings(); }, []);
  useEffect(() => { fetchHistory(); }, []);

  const LockIcon = (lockIconProps : { restricted : boolean }) => (
    <LockIconContainer>
      <img src={lockIconProps.restricted ? lockRedIcon : lockGreenIcon} alt={lockIconProps.restricted ? 'Restricted' : 'Unrestricted'} height={15} width={15} />
    </LockIconContainer>
  );

  return (
    <>
      <Container>
        <StyledBreadcrumb>
          <BreadcrumbItem href={`/learners/${learnerId}`}>Back to Learner Record</BreadcrumbItem>
        </StyledBreadcrumb>
        <WithLoading
          loading={identityStatus === 'initial' || identityStatus === 'loading'}
          loadingText="Loading learner name"
        >
          <WithErrorHandling error={identityResponseError}>
            <RowTitle>{officialName}</RowTitle>
          </WithErrorHandling>
        </WithLoading>
        <WithLoading
          loading={privacySettingsStatus === 'initial' || privacySettingsStatus === 'loading'}
          loadingText="Loading current privacy settings"
        >
          <WithErrorHandling error={privacySettingsResponseError}>
            <PrivacySettings>
              <h5><strong>Settings Effective from</strong> {privacySettings?.EffectiveDate ? moment(privacySettings?.EffectiveDate).format('MMM DD, YYYY') : 'N/A'}</h5>
              <Row>
                <Col xs={1}><LockIcon restricted={privacySettings.Restricted} /></Col>
                <Col>Directory Information</Col>
              </Row>
              <TopRow>
                <Col xs={1}><LockIcon restricted={!privacySettings.ReleaseAcademicInfo} /></Col>
                <Col>Academic Information</Col>
              </TopRow>
              <Row>
                <Col xs={1}><LockIcon restricted={!privacySettings.ReleaseAccountInfo} /></Col>
                <Col>Learner Account Information</Col>
              </Row>
              {!!privacySettings.AuthorizedContacts && !!privacySettings.AuthorizedContacts.length
              && (
              <Contacts>
                <TopRow>
                  <Col xs={1} />
                  <Col><strong>Authorised person(s) or organisation(s):</strong></Col>
                </TopRow>
                {privacySettings.AuthorizedContacts?.map((contact, index) => (
                  <Row>
                    <Col xs={1} />
                    <Col>{index + 1}. {contact.ContactName} / {contact.ContactInformation}</Col>
                  </Row>
                ))}
              </Contacts>
              )}
              <TopRow>
                <Col xs={1}><LockIcon restricted={!privacySettings.PartnerName} /></Col>
                <Col>Partner: {(privacySettings.PartnerName)}</Col>
              </TopRow>
            </PrivacySettings>
          </WithErrorHandling>
        </WithLoading>
        <WithLoading
          loading={historyStatus === 'initial' || historyStatus === 'loading'}
          loadingText="Loading privacy settings history"
        >
          <RowTitle>History</RowTitle>
          <WithErrorHandling error={historyResponseError}>
            {history.length > 1 && history.slice(1).map((h) => (
              <PrivacySettingsHistory>
                <h5><strong>{h.effectiveFrom ? h.effectiveFrom.format('MMM DD, YYYY') : ''} {h.effectiveTill ? `- ${h.effectiveTill.format('MMM DD, YYYY')}` : ''}</strong></h5>
                <TopRow>
                  <Col xs={3}><LockIcon restricted={h.restricted} />Directory Information</Col>
                  <Col xs={6}>
                    <LockIcon restricted={!h.releaseAcademicInfo} />
                  Academic Information
                    <LockIcon restricted={!h.releaseAccountInfo} />
                  Learner Account Information
                  </Col>
                  <Col xs={3}><LockIcon restricted={!h.partnerName} />Partner: {h.partnerName}</Col>
                </TopRow>
                <Contacts>
                  <TopRow>
                    <Col xs={3} />
                    <Col xs={6}><strong>Authorised person(s) or organisation(s):</strong></Col>
                    <Col />
                  </TopRow>
                  {h.authorizedContacts?.map((contact, index) => (
                    <Row>
                      <Col xs={3} />
                      <Col xs={6}>{index + 1}. {contact.contactName} / {contact.contactInformation}</Col>
                      <Col />
                    </Row>
                  ))}
                </Contacts>
              </PrivacySettingsHistory>
            ))}
          </WithErrorHandling>
        </WithLoading>
      </Container>
    </>
  );
};

export default LearnerPrivacySettingsPage;
