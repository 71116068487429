import React, { useEffect, useState } from 'react';
import {
  Container,
  BreadcrumbItem,
  Button,
} from 'react-bootstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  StripedTable,
  UpdateFXRateContainer,
  SmallTextContainer,
} from './styled.components';
import AuthProps from '../auth/auth-props';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import api from '../../shared/api/adminUI.api';
import IError from '../../models/Error';
import { WithPermissions } from '../helper-components/view-permission.component';
import { StyledBreadcrumb } from '../../shared/styled-components';

interface IFXRateHistoryItem {
  rateId: string;
  fxRate: number;
  effectiveFrom: string;
  effectiveUntil: string | null;
}

interface IFXRatePermission {
  FxRateEditor: boolean
}

const FXRatePage = (props: AuthProps) => {
  const { getBearerToken } = props;
  const { currency } = useParams() as { currency : string};

  const [rates, setRates] = useState<Array<IFXRateHistoryItem>>([] as IFXRateHistoryItem[]);
  const [newRate, setNewRate] = useState('');
  const [newDate, setDate] = useState<Date | null>();
  const [actualRate, setActualRate] = useState<IFXRateHistoryItem | null>();
  const [status, setStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');

  const [responseError, setResponseError] = useState<IError | null>(null);
  const [updateResponseError, setUpdateResponseError] = useState<IError | null>(null);
  const [validationError, setValidationError] = useState<string | null>();
  const [permission, setPermission] = useState<boolean>(false);

  const getPermission = async () => {
    try {
      const response = await api.admissions.getFXRatePermission(getBearerToken);
      const perm = (await response.json()) as IFXRatePermission;

      setPermission(perm.FxRateEditor);
    } catch (err) {
      setStatus('error');
      setResponseError(err as IError);
      setPermission(false);
    }
  };

  const fetchHistory = async () => {
    try {
      setStatus('loading');
      const response = await api.fxRates.getRateWithHistory(currency, getBearerToken);
      if (!response.ok) throw await response;

      const newRates = (await response.json()) as Array<IFXRateHistoryItem>;

      setRates(newRates);
      setStatus('success');
      if (newRates.length > 0) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const actualRateItem = newRates.find((item) => {
          const effectiveFromDate = new Date(item.effectiveFrom);
          const effectiveUntilDate = item.effectiveUntil === null ? null : new Date(item.effectiveUntil);

          return effectiveFromDate < now
                    && (effectiveUntilDate === null
                    || effectiveUntilDate > now);
        });

        if (actualRateItem != null) {
          setActualRate(actualRateItem);
        }

        setNewRate(newRates[0].fxRate.toString());
      }
    } catch (err) {
      setStatus('error');
      setResponseError(err as IError);
    }
  };

  useEffect(() => {
    const defaultDatePickerValue = new Date();
    defaultDatePickerValue.setHours(0, 0, 0, 0);
    setDate(defaultDatePickerValue);

    getPermission();
    fetchHistory();
  }, []);

  const updateRate = async () => {
    const newRateNumber = Number(newRate);
    const latestRateNumber = Number(actualRate?.fxRate);
    setValidationError(null);

    if (newRateNumber <= 0) {
      setValidationError('The new rate should be a positive number');
      return;
    }

    if (newRateNumber === latestRateNumber) {
      setValidationError('The rate is the same as the latest rate');
      return;
    }

    const diff = Math.abs((newRateNumber - latestRateNumber) / ((newRateNumber + latestRateNumber) / 2));
    if (diff > 0.2) {
      setValidationError('The rate change is too high. The difference should not exceed 20%');
      return;
    }

    if (rates.find((item) => {
      const effectiveFromDate = new Date(item.effectiveFrom);
      return effectiveFromDate === newDate;
    })) {
      setValidationError('The rate for this date already exists');
      return;
    }

    try {
      const response = await api.fxRates.updateFXRate(currency, Number(newRate), moment(newDate).format('yyyy-MM-DD'), getBearerToken);

      if (!response.ok) throw await response;
      fetchHistory();
    } catch (err) {
      setUpdateResponseError(err as IError);
    }
  };

  return (
    <>
      <Container>
        <StyledBreadcrumb>
          <BreadcrumbItem href="/">Home</BreadcrumbItem>
          <BreadcrumbItem href="/fxrates">FX Rates</BreadcrumbItem>
          <BreadcrumbItem active>{currency}</BreadcrumbItem>
        </StyledBreadcrumb>
        <WithPermissions permission={permission} noAccesText="">
          <UpdateFXRateContainer>
            <h4><b>Edit FX rate: {currency}</b></h4>
            <SmallTextContainer>Current Rate:   {actualRate?.fxRate} per $1.00 | Effective from: {moment(actualRate?.effectiveFrom).format('YYYY-MM-DD')}</SmallTextContainer>
            <div>
              <span>Effective Date</span>
              <DatePicker wrapperClassName="datePicker" minDate={new Date()} selected={newDate} dateFormat="yyyy-MM-dd" onChange={(date) => setDate(date)} />
            </div>
            <div>
              <span>FX Rate (per $1.00)</span><input
                type="number"
                defaultValue={newRate}
                onChange={(e) => {
                  setNewRate(e.target.value);
                }}
              />
            </div>
            <div>
              <Button onClick={() => updateRate()}>Save</Button>
              <span className="error_lable">{validationError}</span>
            </div>
            <WithErrorHandling error={updateResponseError} />

          </UpdateFXRateContainer>
        </WithPermissions>
        <WithLoading
          loading={status === 'initial' || status === 'loading'}
          loadingText="Loading rate history"
        >
          <WithErrorHandling error={responseError}>
            <StripedTable>
              <tr>
                <th>Effective From</th>
                <th>End Date</th>
                <th>FX Rate (per $1.00)</th>
              </tr>
              {rates.map((rate) => (
                <tr>
                  <td>{moment(rate.effectiveFrom).format('YYYY-MM-DD')}</td>
                  <td>{rate.effectiveUntil === null ? '' : moment(rate.effectiveUntil).format('YYYY-MM-DD')}</td>
                  <td>{rate.fxRate}</td>
                </tr>
              ))}
            </StripedTable>
          </WithErrorHandling>
        </WithLoading>
      </Container>
    </>
  );
};

export default FXRatePage;
